import React from 'react';
import loadable from '@loadable/component';

import { Layout } from '@components/layout';

const Home = loadable(() => import('@screens/Home'));

function Index(props) {
  return (
    <Layout {...props}>
      <Home />
    </Layout>
  );
}

export default Index;
